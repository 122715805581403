import React, { useEffect, useState } from 'react';
import './LandingPage.css';

const LandingPage = () => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const letters = "あいうえおかきくけこたちつてとらりるれろまみむめもなにぬねのはひふへほやゆよわをん";
    const h1Element = document.querySelector("h1");

    const lines = [
      { text: "コード流れ", length: 5 },
      { text: "刀の鋭さで", length: 5 },
      { text: "秋の技", length: 3 }
    ];

    let lineIndex = 0;
    let iterations = 0;
    let interval = null;

    const scrambleLine = (line) => {
      interval = setInterval(() => {
        const scrambledLine = line.text
          .split("")
          .map((letter, index) => {
            if (index < iterations) {
              return line.text[index];
            }
            return letters[Math.floor(Math.random() * letters.length)];
          })
          .join("");

        const updatedText = lines.map((l, i) => {
          return i < lineIndex ? l.text : (i === lineIndex ? scrambledLine : l.text);
        }).join("<br />");

        h1Element.innerHTML = updatedText;

        if (iterations >= line.length) {
          clearInterval(interval);
          lineIndex += 1;
          if (lineIndex < lines.length) {
            iterations = 0;
            scrambleLine(lines[lineIndex]);
          }
        }

        iterations += 1;
      }, 75);
    };

    const resetAnimation = () => {
      if (h1Element) {
        // Reset the haiku text and apply fade-out and slide-up reset
        h1Element.style.opacity = "0";  
        h1Element.style.transform = "translateY(30px)";  // Start from below
        setTimeout(() => {
          lineIndex = 0;
          iterations = 0;
          h1Element.innerHTML = ''; // Clear current text
          h1Element.style.opacity = "1";  // Fade back in
          h1Element.style.transform = "translateY(0)"; // Slide up
          scrambleLine(lines[lineIndex]); // Restart the animation
        }, 200); // Small delay for smoother transition
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            resetAnimation();  // Restart the animation smoothly
          }
        });
      },
      { threshold: 0.5 }
    );

    const section = document.querySelector('.vertical-text-container');
    if (section) {
      observer.observe(section);
    }

    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 3000); // Show scroll indicator after 3 seconds

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <div className="vertical-text-container">
      <h1 className="text-white text-5xl font-custom">
        コード流れ<br />刀の鋭さで<br />秋の技
      </h1>

      {showScrollIndicator && <div className="scroll-indicator"></div>}
    </div>
  );
};

export default LandingPage;
