import React, { useState, useEffect } from 'react';
import LandingPage from './components/LandingPage';
import VideoSection from './components/VideoSection';
import AboutMe from './components/AboutMe';
import InfiniteScrollSection from './components/InfiniteScrollSection';
import ContactSection from './components/ContactSection'; // Import the new ContactSection component
import './App.css'; // Make sure to add this for the hamburger menu and transition styles

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('landing');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId); // Trigger the section change
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    toggleMenu();
  };

  useEffect(() => {
    const sections = document.querySelectorAll('section');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id); // Set active section when in view
          }
        });
      },
      { threshold: 0.7 } // Set a threshold to detect when sections are fully visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect(); // Cleanup on component unmount
  }, []);

  return (
    <div className={`app-container ${activeSection}`}>
      {/* Hamburger Menu */}
      <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Full Screen Overlay Menu */}
      <div className={`overlay-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><button onClick={() => handleSectionChange('landing')}>Home</button></li>
          <li><button onClick={() => handleSectionChange('video')}>Video</button></li>
          <li><button onClick={() => handleSectionChange('about')}>About Me</button></li>
          <li><button onClick={() => handleSectionChange('infinite-scroll')}>Projects</button></li>
          <li><button onClick={() => handleSectionChange('contact')}>Contact</button></li> {/* Added Contact section link */}
        </ul>
      </div>

      {/* Landing Page Section */}
      <section id="landing" className={`section fade ${activeSection === 'landing' ? 'visible' : ''}`}>
        <LandingPage />
      </section>

      {/* Video Section */}
      <section id="video" className={`section fade ${activeSection === 'video' ? 'visible' : ''}`}>
        <VideoSection />
      </section>

      {/* About Me Section */}
      <section id="about" className={`section fade ${activeSection === 'about' ? 'visible' : ''}`}>
        <AboutMe />
      </section>

      {/* Infinite Scroll Section */}
      <section id="infinite-scroll" className={`section fade ${activeSection === 'infinite-scroll' ? 'visible' : ''}`}>
        <InfiniteScrollSection />
      </section>

      {/* Contact Section */}
      <section id="contact" className={`section fade ${activeSection === 'contact' ? 'visible' : ''}`}>
        <ContactSection /> {/* New ContactSection */}
      </section>
    </div>
  );
}

export default App;
