import React, { useRef, useEffect, useState } from 'react';
import './AboutMe.css';
import profileImage from '../assets/IMG_5971.png';

const AboutMe = () => {
  const sectionRef = useRef(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 3000); // Show scroll indicator after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div ref={sectionRef} className="about-me-container">
      <div className="about-content">
        {/* Profile Image with animation */}
        <div className="profile-image-wrapper">
          <img src={profileImage} alt="Profile" className="profile-image" />
        </div>

        {/* Introduction */}
        <div className="about-text">
          <h2 className="about-title">Hello there, I'm Aki</h2>
          <p className="about-description">
            I am a Computer Science and Japan Studies undergraduate. I am passionate about technology and pursuing a career in software engineering, finance, AI, and Machine Learning related topics. As a person, I am constantly exploring ways to improve myself. I strive for continuous learning and I'm excited about the potential of combining my skills to create impactful solutions. Here, you'll learn more about my journey, my work, and my aspirations.
          </p>
        </div>
      </div>

      {/* Experience Timeline - Horizontal Layout */}
      <div className="experience-section">
        <h3 className="experience-title">Experience</h3>
        <div className="horizontal-timeline">
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              <h4>Software and Computer Engineering Intern</h4>
              <p>Qualcomm, Mexico City, 2016 - 6 months internship</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              <h4>Freelance Developer & Digital Artist</h4>
              <p>Vancouver BC | Mexico City, 2020-2024</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              <h4>Software R&D Engineer Intern</h4>
              <p>TUPL, Vancouver BC | Seattle, WA, 2024</p>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      {showScrollIndicator && <div className="scroll-indicator"></div>}
    </div>
  );
};

export default AboutMe;
