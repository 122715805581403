import { useState } from 'react';
import { motion } from 'framer-motion';
import { Github, Linkedin, Youtube } from 'lucide-react';
import emailjs from 'emailjs-com';
import './ContactSection.css';

const ContactSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Your EmailJS credentials
    const SERVICE_ID = 'service_l3eisjb'; // Use the Service ID from EmailJS
    const TEMPLATE_ID = 'template_puxr3kc'; // Replace with your actual Template ID
    const USER_ID = 'KuOF1i8Ku3AHjUB2O'; // Replace with your EmailJS User ID (find it under Account > API Keys)

    try {
      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Failed to send email:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-heading">LET'S GET IN TOUCH</h2>
      {!isSubmitted ? (
        <motion.form
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          onSubmit={handleSubmit}
          className="contact-form"
        >
          <motion.div whileTap={{ scale: 0.98 }}>
            <label htmlFor="from_name" className="input-label">Name</label>
            <motion.input
              whileFocus={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300 }}
              type="text"
              id="from_name"
              name="from_name" // Changed to "from_name" to match the template placeholder
              required
              className="input-field"
            />
          </motion.div>
          <motion.div whileTap={{ scale: 0.98 }}>
            <label htmlFor="reply_to" className="input-label">Email</label>
            <motion.input
              whileFocus={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300 }}
              type="email"
              id="reply_to"
              name="reply_to" // Changed to "reply_to" to match the template placeholder
              required
              className="input-field"
            />
          </motion.div>
          <motion.div whileTap={{ scale: 0.98 }}>
            <label htmlFor="message" className="input-label">Message</label>
            <motion.textarea
              whileFocus={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300 }}
              id="message"
              name="message"
              required
              rows={4}
              className="input-field"
            ></motion.textarea>
          </motion.div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={isSubmitting}
            className="submit-button"
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </motion.button>
        </motion.form>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="thank-you-message"
        >
          <h2 className="thank-you-title">Thank you!</h2>
          <p>Your message has been sent successfully.</p>
        </motion.div>
      )}
      <div className="social-icons">
        <motion.a
          href="https://github.com/akiblasco"
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          className="icon"
        >
          <Github size={24} />
          <span className="sr-only">GitHub</span>
        </motion.a>
        <motion.a
          href="https://www.linkedin.com/in/inakiblasco/"
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          className="icon"
        >
          <Linkedin size={24} />
          <span className="sr-only">LinkedIn</span>
        </motion.a>
        <motion.a
          href="https://www.youtube.com/@aki_blasco"
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          className="icon"
        >
          <Youtube size={24} />
          <span className="sr-only">YouTube</span>
        </motion.a>
      </div>
    </div>
  );
};

export default ContactSection;
