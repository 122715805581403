import React, { useRef, useEffect, useState } from 'react';
import './VideoSection.css';
import promoVideoMp4 from '../assets/output_compressed.mp4'; // Optimized video version
import { Youtube } from 'lucide-react';
import posterImage from '../assets/Artboard 1-1.png'; // Poster image to show before video loads

const VideoSection = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const currentVideoRef = videoRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            currentVideoRef.play().catch((error) => {
              console.log('Autoplay was prevented:', error);
            });
            setIsVisible(true);
          } else {
            currentVideoRef.pause();
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }

    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 3000);

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="video" className="video-container">
      <a
        href="https://www.youtube.com/watch?v=OCgWg95BhVU&lc=UgwrprapwQMdl7FHBoV4AaABAg"
        target="_blank"
        rel="noopener noreferrer"
        className="youtube-link-icon"
      >
        <Youtube size={28} />
        <span className="sr-only">Watch on YouTube</span>
      </a>

      <video
        ref={videoRef}
        className={`self-promo-video ${isVisible ? 'fade-in' : ''}`}
        poster={posterImage} // Use a lightweight image to show before the video loads
        preload="metadata" // Preload only metadata for better load performance
        playsInline
        loop
        muted
        controls
      >
        <source src={promoVideoMp4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {showScrollIndicator && <div className="scroll-indicator"></div>}
    </div>
  );
};

export default VideoSection;
