import React, { useState, useEffect } from 'react';
import './InfiniteScrollSection.css';

import berlinImg from 'src/assets/TravelAgency/BerlinBlink.png';
import fujiImg from 'src/assets/TravelAgency/FujiBlink.png';
import nycImg from 'src/assets/TravelAgency/NYCBlink.png';
import payment from 'src/assets/TravelAgency/PaymentBlink.png';
import filter from 'src/assets/RestaurantRecommendation/FilterReview.png';
import insert from 'src/assets/RestaurantRecommendation/InsertReview.png';
import retrieve from 'src/assets/RestaurantRecommendation/RetrieveReview.png';
import language from 'src/assets/MiniLanguage/Language.png';
import console from 'src/assets/HaskellPong/PongConsole.png';
import gui from 'src/assets/HaskellPong/PongGUI.png';
import api from 'src/assets/JapaneseChatbot/API.png';
import final from 'src/assets/JapaneseChatbot/FinalLook.png';
import grammar from 'src/assets/JapaneseApp/Grammar.png';
import neural from 'src/assets/JapaneseApp/NeuralNetworks.png';
import similar from 'src/assets/JapaneseApp/SimilarKanji.png';
import ascii from 'src/assets/PythonAscii/Ascii.png';
import terminal from 'src/assets/PythonAscii/AsciiTerminal.png';
import stocks from 'src/assets/StockPredictor/ModelResults.png';
import dataplots from 'src/assets/FitnessMarket/DataPlots.png'
import heatmap from 'src/assets/FitnessMarket/Heatmap.png'
import observation from 'src/assets/UberAnalysis/Observations.png';

const InfiniteScrollSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 3000); // Show scroll indicator after 3 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  // Projects with images for each
  const projects = [
    { title: 'Travel Agency Website', description: 'Developed and designed a fully functional travel agency web app with search, reservations, database, and payment systems', images: [fujiImg, nycImg, berlinImg, payment] },
    { 
        title: 'Restaurant Guide Review System', 
        description: 'Relational Database team project where our team designed a review system app using PHP, SQL and Git.', 
        images: [filter, insert, retrieve] 
    },
    { 
        title: 'Haskell Pong', 
        description: 'Developed in a team project game, featuring console and GUI versions with user interaction for paddle control, and AI.', 
        images: [console, gui] 
    },
    { 
        title: 'Mini Programming Language', 
        description: 'Team developed a custom mini programming language with basic syntax and parsing functionality. I personally developed the parser.', 
        images: [language] 
    },    
    { 
        title: 'Japanese Chatbot', 
        description: 'Developed an AI chatbot using OpenAI’s API for prompt engineering and AI-assisted Japanese language learning research.', 
        images: [api, final] 
    },
    { 
        title: 'Japanese Learning App', 
        description: 'Theoretical AI-based language learning project using self-designed neural networks, with a focus on Japanese language processing.', 
        images: [grammar, neural, similar] 
    },    
    { 
        title: 'Python ASCII Art', 
        description: 'Created dynamic ASCII art animations in Python using terminal-based visuals and libraries like os, platform, time, and random.', 
        images: [ascii, terminal] 
    },
    { 
        title: 'Stock Market Predictor', 
        description: 'Developed a machine learning model to predict stock market trends using historical data and technical indicators.', 
        images: [stocks] 
    },
    { 
        title: 'Fitness Market Analysis', 
        description: 'Analyzed fitness market trends using machine learning algorithms to identify patterns and insights from data visualizations.', 
        images: [dataplots, heatmap] 
    },
    { 
        title: 'Uber Trip Analysis', 
        description: 'Applied AI techniques to analyze Uber trip data for predicting ride patterns and optimizing trip durations.', 
        images: [observation] 
    }
    
  ];

  return (
    <div className="portfolio-section">
      <h2 className="portfolio-title">Portfolio</h2>
      <div className="wrapper">
        <div className="inner">
          {projects.concat(projects).map((project, index) => ( // Duplicate projects array for seamless looping
            <ProjectItem 
              key={index} 
              project={project} 
              isHovered={hoveredIndex === index} 
              onMouseEnter={() => setHoveredIndex(index)} 
              onMouseLeave={() => setHoveredIndex(null)} 
            />
          ))}
        </div>
      </div>
      {/* Scroll Indicator */}
      {showScrollIndicator && <div className="scroll-indicator"></div>}
    </div>
  );
};

const ProjectItem = ({ project, isHovered, onMouseEnter, onMouseLeave }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (project.images.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % project.images.length);
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [project.images.length]);

  return (
    <div 
      className={`item ${isHovered ? 'hovered' : ''}`} 
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}
    >
      <div className="item-title">{project.title}</div>
      <div className="image-slider">
        <img 
          src={project.images[currentImageIndex]} 
          alt={`${project.title} ${currentImageIndex + 1}`} 
          className="item-image"
        />
      </div>
      <div className="item-details">{project.description}</div>
    </div>
  );
};

export default InfiniteScrollSection;
